.App {
    text-align: center;
}

@font-face {
    font-family: "regular";
    src: url(./fonts/Geometria.ttf) format("truetype");
}

@font-face {
    font-family: "bold";
    src: url(./fonts/Geometria-Bold.ttf) format("truetype");
}

body,
html {
    font-family: regular, Sans-Serif;
    overscroll-behavior-y: none;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes in {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
a {
    color: white;
}

.animate-in {
    animation-name: in;
    animation-duration: 1s;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
